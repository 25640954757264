<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-cierre-guia-export"
      style="background-color: #00000082"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar Reporte General</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="" class="col-md-5">Fecha Enturne</label>
                    <input
                      type="date"
                      id="fecha_enturne"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.fecha_enturne"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="" class="col-md-5">Fecha Descargue</label>
                    <input
                      type="date"
                      id="fecha_descargue"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.fecha_descargue"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="" class="col-md-5">Fecha Cargue Guia</label>
                    <input
                      type="date"
                      id="fecha_expedicion"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.fecha_expedicion"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="n_guia" class="col-md-5">N° GUIA</label>
                    <input
                      type="number"
                      id="n_guia"
                      v-model="filtros.nGuia"
                      class="form-control form-control-sm col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="ventana_id" class="col-md-5"
                      >Ventana Descargue</label
                    >
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="ventana"
                      placeholder="Ventana Descargue"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.ventana_descargue"
                      :filterable="true"
                      @input="getSelectVentanaDescargue()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="placa" class="col-md-5">PLACA VEHICULO</label>
                    <input
                      type="text"
                      id="placa"
                      v-model="filtros.nPlaca"
                      class="form-control form-control-sm col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="novedad_1" class="col-md-5">Novedades 1</label>
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.novedad_1"
                    >
                      <option value="">Seleccione</option>
                      <option
                        v-for="novedad_1 in listasForms.novedades"
                        :key="novedad_1.numeracion"
                        :value="novedad_1.numeracion"
                      >
                        {{ novedad_1.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="novedad_2" class="col-md-5">Novedades 2</label>
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.novedad_2"
                      @change="cargarCierre()"
                    >
                      <option value="">Seleccione</option>
                      <option
                        v-for="novedad_2 in listasForms.novedades"
                        :key="novedad_2.numeracion"
                        :value="novedad_2.numeracion"
                      >
                        {{ novedad_2.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="$store.getters.can('hidrocarburos.cierreGuias.export')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "ReporteGeneralExport",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      ventana: {},
      filtros: {
        nGuia: null,
        nPlaca: null,
        fecha_enturne: null,
        fecha_descargue: null,
        fecha_expedicion: null,
        ventana_id: null,
        novedad_1: null,
        novedad_2: null,
      },
      listasForms: {
        novedades: [],
        ventana_descargue: [],
      },
    };
  },
  methods: {
    async getNovedades() {
      axios.get("/api/lista/52").then((response) => {
        this.listasForms.novedades = response.data;
      });
    },
    async getVentanaEnturnamiento() {
      axios
        .get("api/hidrocarburos/ventanasEnturnamiento/lista")
        .then((response) => {
          this.listasForms.ventana_descargue = response.data;
        });
    },
    getSelectVentanaDescargue() {
      this.filtros.ventana = {};
      this.filtros.ventana_id = null;
      if (this.ventana) {
        this.filtros.ventana = this.ventana;
        this.filtros.ventana_id = this.ventana.id;
      }
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/cierreGuias/export",
        data: { filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  mounted() {
    this.cargando = true;
    // await
    this.getNovedades();
    this.getVentanaEnturnamiento();
    this.cargando = false;
  },
};
</script>
